import type { ModelsIconItem, ComponentProps } from "../../../types";
import {
  iconItemVariant,
  type IconItemVariantProps,
} from "./icon-item.variants";

export type UseIconItemProps = ComponentProps<
  ModelsIconItem,
  IconItemVariantProps
>;

export const useIconItem = (props: UseIconItemProps) => {
  const { className = "", theme = "dark", data } = props;
  const styles = iconItemVariant({ theme });

  return {
    theme,
    className,
    styles,
    data,
  };
};
