import NextLink from "next/link";
import { AnimatePresence, m } from "motion/react";
import type { LayoutNavItemDropdown } from "../../../types";
import { slugify } from "../../../util/text";
import { EventAction } from "../../../config/events";
import useUTMParams from "../../../hooks/use-utm-params";
import { Vector } from "../../ui/vector";
import { Button } from "../../ui/button";
import { headerDropdownVariant } from "./header.variants";
import useHeaderState from "./use-header-state";

interface HeaderDropdownProps {
  hasCta: boolean;
  items: LayoutNavItemDropdown[];
  isExpanded: boolean;
  dropdownId: number;
  dropdownLabel: string;
}

const variants = {
  enter: {
    opacity: 1,
    y: 25,
    display: "block",
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
};

function HeaderDropdown({
  hasCta,
  items,
  isExpanded,
  dropdownLabel,
}: HeaderDropdownProps) {
  const { theme, cancelCloseDropdown, mobile } = useHeaderState();
  const { appendUTMParams } = useUTMParams();

  const styles = headerDropdownVariant({ theme, hasCta });

  const dropdownMenu = hasCta ? items.slice(0, items.length - 1) : items;
  const cta = hasCta ? items[items.length - 1] : null;

  return (
    <AnimatePresence>
      {!!isExpanded || mobile ? (
        <m.div
          animate={variants.enter}
          aria-labelledby={dropdownLabel}
          className={styles.base()}
          exit={variants.exit}
          initial={variants.exit}
          onMouseEnter={() => {
            cancelCloseDropdown();
          }}
          variants={variants}
        >
          <div className={styles.card()}>
            <ul
              className={`${styles.list()} mobile:pl-[20px] mobile:border-l mobile:border-white/20`}
            >
              {dropdownMenu.map(
                ({ title, description, link, openNewWindow, startIcon }, i) => {
                  const slugifiedSectionName = slugify(`header_${title}`);
                  const url = appendUTMParams(link, slugifiedSectionName);

                  return (
                    <li className={styles.item()} key={`${dropdownLabel}-${i}`}>
                      {hasCta ? (
                        <>
                          {!!startIcon && (
                            <div className={styles.iconWrapper()}>
                              <Vector
                                className={styles.icon()}
                                name={startIcon}
                              />
                            </div>
                          )}
                          <div>
                            <NextLink
                              className={styles.link()}
                              href={url}
                              target={openNewWindow ? "_blank" : "_self"}
                            >
                              {title}
                              <span className="absolute inset-0" />
                            </NextLink>
                            {!!description && (
                              <p className={styles.description()}>
                                {description}
                              </p>
                            )}
                          </div>
                        </>
                      ) : (
                        <NextLink
                          className={styles.link()}
                          data-event-action={EventAction.WebsiteClick}
                          data-event-label={slugifiedSectionName}
                          href={url}
                          tabIndex={0}
                          target={openNewWindow ? "_blank" : "_self"}
                          title={title}
                        >
                          {title}
                        </NextLink>
                      )}
                    </li>
                  );
                }
              )}
            </ul>
            {cta ? (
              <div className={styles.cta()}>
                <div>
                  <h3 className={styles.link()}>{cta.title}</h3>
                  <p
                    className={styles.description({
                      className: "text-display-16",
                    })}
                  >
                    {cta.description}
                  </p>
                </div>
                <Button
                  appearance="solid"
                  className="my-auto shrink-0"
                  color="blue"
                  endIcon="arrow-right"
                  href={cta.link}
                  sectionName={`header_${cta.cta}`}
                  size="sm"
                  title={cta.cta}
                >
                  {cta.cta}
                </Button>
              </div>
            ) : null}
          </div>
        </m.div>
      ) : null}
    </AnimatePresence>
  );
}

export default HeaderDropdown;
