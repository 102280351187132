import { useState } from "react";
import type {
  ModelsDownloadCard,
  SectionProps,
  SectionsCardContentGrid,
  SectionsDownloads,
} from "../../../types";

export type UseDownloadsProps = SectionProps<SectionsDownloads>;

export const useDownloads = (props: UseDownloadsProps) => {
  const { className = "", sectionName, data: dataProp } = props;
  const { files, ...restData } = dataProp;

  const [modalState, setModalState] = useState(false);
  const [currentFile, setCurrentFile] = useState<ModelsDownloadCard>();

  const data: SectionsCardContentGrid = {
    ...restData,
    headerLayout: "top",
    columns: 3,
    cardAppearance: "card",
    list: [],
    cards: files.map((file) => ({
      id: file.id,
      icon: file.icon,
      title: file.title,
      description: file.description,
      list: [],
      link: {
        text: "Download",
        startIcon: "download",
        endIcon: null,
        onClick: () => {
          setCurrentFile(file);
          setModalState(true);
        },
      },
    })),
  };

  return {
    sectionName,
    className,
    data,
    currentFile,
    modalState,
    setModalState,
  };
};
