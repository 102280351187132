"use client";

import { motion } from "motion/react";
import TimelineItem from "./timeline-item";
import TimelineToday from "./timeline-today";
import {
  useTimelineList,
  type UseTimelineListProps,
} from "./use-timeline-list";

export type TimelineListProps = UseTimelineListProps;

const TimelineList = (props: TimelineListProps) => {
  const { styles, theme, timelineRef, active, scrollHeight, timeline, today } =
    useTimelineList(props);

  return (
    <div ref={timelineRef} className={styles.base()}>
      <div className={styles.wrapper()}>
        <motion.ul
          className={styles.list()}
          style={{
            // @ts-ignore
            "--timeline-progress": scrollHeight,
          }}
        >
          {timeline.map((item) => (
            <TimelineItem
              key={item[0]}
              theme={theme}
              data={{
                year: item[0],
                milestones: item[1],
              }}
            />
          ))}
        </motion.ul>
      </div>
      <TimelineToday
        theme={theme}
        active={active}
        title="Today"
        today={today}
      />
    </div>
  );
};

export default TimelineList;
