import AB2 from "./a-b-2.svg";
import AdjustmentsVertical from "./adjustments-vertical.svg";
import Adjustments from "./adjustments.svg";
import ArrowDown from "./arrow-down.svg";
import ArrowLeft from "./arrow-left.svg";
import ArrowRight from "./arrow-right.svg";
import ArrowUp from "./arrow-up.svg";
import ArrowsDiff from "./arrows-diff.svg";
import ArrowsJoin from "./arrows-join.svg";
import ArrowsMaximize from "./arrows-maximize.svg";
import ArrowsSplit from "./arrows-split.svg";
import Atom from "./atom.svg";
import AudioEncoder from "./audio-encoder.svg";
import AudioFile from "./audio-file.svg";
import AudioMixer from "./audio-mixer.svg";
import BackingVocalsStem from "./backing-vocals-stem.svg";
import BassStem from "./bass-stem.svg";
import BeatDetection from "./beat-detection.svg";
import BeatsTranscription from "./beats-transcription.svg";
import Bolt from "./bolt.svg";
import Box from "./box.svg";
import BpmDetection from "./bpm-detection.svg";
import Brand from "./brand.svg";
import Car from "./car.svg";
import ChatBubbleBottomCenterText from "./chat-bubble-bottom-center-text.svg";
import Check from "./check.svg";
import ChevronDown from "./chevron-down.svg";
import ChevronRight from "./chevron-right.svg";
import ChevronUp from "./chevron-up.svg";
import ChordRecognition from "./chord-recognition.svg";
import Chords from "./chords.svg";
import CircuitSwitchClosed from "./circuit-switch-closed.svg";
import Clipboard from "./clipboard.svg";
import Clock from "./clock.svg";
import Cpu2 from "./cpu-2.svg";
import Crown from "./crown.svg";
import Database from "./database.svg";
import Denoiser from "./denoiser.svg";
import Devices from "./devices.svg";
import Download from "./download.svg";
import DrumsStemTop from "./drums-stem-top.svg";
import DrumsStem from "./drums-stem.svg";
import ExternalLink from "./external-link.svg";
import Facebook from "./facebook.svg";
import Flash from "./flash.svg";
import Folders from "./folders.svg";
import GitBranch from "./git-branch.svg";
import GuitarAmp from "./guitar-amp.svg";
import GuitarStem from "./guitar-stem.svg";
import Headphones from "./headphones.svg";
import Info from "./info.svg";
import Instagram from "./instagram.svg";
import Limiter from "./limiter.svg";
import Linkedin from "./linkedin.svg";
import Lock from "./lock.svg";
import LyricsTranscription from "./lyrics-transcription.svg";
import Lyrics from "./lyrics.svg";
import Mail from "./mail.svg";
import Mastering from "./mastering.svg";
import MathFunction from "./math-function.svg";
import MessageCircle from "./message-circle.svg";
import Metronome from "./metronome.svg";
import Microphone from "./microphone.svg";
import MusicControl from "./music-control.svg";
import MusicalNote from "./musical-note.svg";
import NoBassStem from "./no-bass-stem.svg";
import NoDrumsStemTop from "./no-drums-stem-top.svg";
import NoGuitarStem from "./no-guitar-stem.svg";
import NoMusicalNote from "./no-musical-note.svg";
import NoVocalSynthesis from "./no-vocal-synthesis.svg";
import OffloadDevice from "./offload-device.svg";
import Overdrive from "./overdrive.svg";
import Palette from "./palette.svg";
import Pause from "./pause.svg";
import Phone from "./phone.svg";
import PianoStem from "./piano-stem.svg";
import PlayAudio from "./play-audio.svg";
import PlayOnRepeat from "./play-on-repeat.svg";
import PlayerPlayFilled from "./player-play-filled.svg";
import QuoteAfterFilled from "./quote-after-filled.svg";
import QuoteBeforeFilled from "./quote-before-filled.svg";
import Quote from "./quote.svg";
import Reverse from "./reverse.svg";
import Rocket from "./rocket.svg";
import Seeding from "./seeding.svg";
import Settings from "./settings.svg";
import Signal from "./signal.svg";
import SongDetail from "./song-detail.svg";
import SoundBox from "./sound-box.svg";
import Sparkles from "./sparkles.svg";
import Speed from "./speed.svg";
import Spliter from "./spliter.svg";
import Square3Stack3d from "./square-3-stack-3d.svg";
import Squares from "./squares.svg";
import Star from "./star.svg";
import StemSeparation from "./stem-separation.svg";
import StringStem from "./string-stem.svg";
import SubtitleAlignment from "./subtitle-alignment.svg";
import SubtitleEncoder from "./subtitle-encoder.svg";
import Tag from "./tag.svg";
import Target from "./target.svg";
import Telegram from "./telegram.svg";
import Tempo from "./tempo.svg";
import Tiktok from "./tiktok.svg";
import TimeStretching from "./time-stretching.svg";
import Tv from "./tv.svg";
import Twitter from "./twitter.svg";
import UserVoice from "./user-voice.svg";
import VideoCamera from "./video-camera.svg";
import VideoMixer from "./video-mixer.svg";
import VocalSynthesis from "./vocal-synthesis.svg";
import Wand from "./wand.svg";
import Whatsapp from "./whatsapp.svg";
import WritingSign from "./writing-sign.svg";
import XMark from "./x-mark.svg";
import Youtube from "./youtube.svg";

const svg = {
  "a-b-2": AB2,
  "adjustments-vertical": AdjustmentsVertical,
  "adjustments": Adjustments,
  "arrow-down": ArrowDown,
  "arrow-left": ArrowLeft,
  "arrow-right": ArrowRight,
  "arrow-up": ArrowUp,
  "arrows-diff": ArrowsDiff,
  "arrows-join": ArrowsJoin,
  "arrows-maximize": ArrowsMaximize,
  "arrows-split": ArrowsSplit,
  "atom": Atom,
  "audio-encoder": AudioEncoder,
  "audio-file": AudioFile,
  "audio-mixer": AudioMixer,
  "backing-vocals-stem": BackingVocalsStem,
  "bass-stem": BassStem,
  "beat-detection": BeatDetection,
  "beats-transcription": BeatsTranscription,
  "bolt": Bolt,
  "box": Box,
  "bpm-detection": BpmDetection,
  "brand": Brand,
  "car": Car,
  "chat-bubble-bottom-center-text": ChatBubbleBottomCenterText,
  "check": Check,
  "chevron-down": ChevronDown,
  "chevron-right": ChevronRight,
  "chevron-up": ChevronUp,
  "chord-recognition": ChordRecognition,
  "chords": Chords,
  "circuit-switch-closed": CircuitSwitchClosed,
  "clipboard": Clipboard,
  "clock": Clock,
  "cpu-2": Cpu2,
  "crown": Crown,
  "database": Database,
  "denoiser": Denoiser,
  "devices": Devices,
  "download": Download,
  "drums-stem-top": DrumsStemTop,
  "drums-stem": DrumsStem,
  "external-link": ExternalLink,
  "facebook": Facebook,
  "flash": Flash,
  "folders": Folders,
  "git-branch": GitBranch,
  "guitar-amp": GuitarAmp,
  "guitar-stem": GuitarStem,
  "headphones": Headphones,
  "info": Info,
  "instagram": Instagram,
  "limiter": Limiter,
  "linkedin": Linkedin,
  "lock": Lock,
  "lyrics-transcription": LyricsTranscription,
  "lyrics": Lyrics,
  "mail": Mail,
  "mastering": Mastering,
  "math-function": MathFunction,
  "message-circle": MessageCircle,
  "metronome": Metronome,
  "microphone": Microphone,
  "music-control": MusicControl,
  "musical-note": MusicalNote,
  "no-bass-stem": NoBassStem,
  "no-drums-stem-top": NoDrumsStemTop,
  "no-guitar-stem": NoGuitarStem,
  "no-musical-note": NoMusicalNote,
  "no-vocal-synthesis": NoVocalSynthesis,
  "offload-device": OffloadDevice,
  "overdrive": Overdrive,
  "palette": Palette,
  "pause": Pause,
  "phone": Phone,
  "piano-stem": PianoStem,
  "play-audio": PlayAudio,
  "play-on-repeat": PlayOnRepeat,
  "player-play-filled": PlayerPlayFilled,
  "quote-after-filled": QuoteAfterFilled,
  "quote-before-filled": QuoteBeforeFilled,
  "quote": Quote,
  "reverse": Reverse,
  "rocket": Rocket,
  "seeding": Seeding,
  "settings": Settings,
  "signal": Signal,
  "song-detail": SongDetail,
  "sound-box": SoundBox,
  "sparkles": Sparkles,
  "speed": Speed,
  "spliter": Spliter,
  "square-3-stack-3d": Square3Stack3d,
  "squares": Squares,
  "star": Star,
  "stem-separation": StemSeparation,
  "string-stem": StringStem,
  "subtitle-alignment": SubtitleAlignment,
  "subtitle-encoder": SubtitleEncoder,
  "tag": Tag,
  "target": Target,
  "telegram": Telegram,
  "tempo": Tempo,
  "tiktok": Tiktok,
  "time-stretching": TimeStretching,
  "tv": Tv,
  "twitter": Twitter,
  "user-voice": UserVoice,
  "video-camera": VideoCamera,
  "video-mixer": VideoMixer,
  "vocal-synthesis": VocalSynthesis,
  "wand": Wand,
  "whatsapp": Whatsapp,
  "writing-sign": WritingSign,
  "x-mark": XMark,
  "youtube": Youtube,
}

export type SvgName = keyof typeof svg;

export { svg };