"use client";

import { Text } from "../../ui/text";
import {
  useTimelineToday,
  type UseTimelineTodayProps,
} from "./use-timeline-today";

export type TimelineTodayProps = UseTimelineTodayProps;

const TimelineToday = (props: TimelineTodayProps) => {
  const { styles, theme, title, today } = useTimelineToday(props);

  return (
    <div className={styles.base()}>
      <div>
        <Text tag="h3" size="h4" className={styles.title()}>
          {title}
        </Text>
        <ul className={styles.list()}>
          {today.map(({ id, title, description }) => (
            <li key={id} className={styles.listItem()}>
              <Text
                theme={theme}
                className={styles.label()}
                tag="h3"
                size="h5"
                weight="medium"
              >
                {title}
              </Text>
              {!!description && (
                <Text
                  theme={theme}
                  className={styles.description()}
                  size="h6"
                  weight="medium"
                >
                  {description}
                </Text>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TimelineToday;
