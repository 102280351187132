import { tv, type VariantProps } from "../../../util/tv";

export const iconItemVariant = tv({
  slots: {
    base: ["flex items-center gap-4"],
    iconWrapper: [
      "flex items-center justify-center",
      "w-8 h-8",
      "shrink-0",
      "rounded-full",
    ],
    icon: ["h-6 w-6"],
    label: [],
  },
  variants: {
    theme: {
      dark: {
        base: "text-white",
        iconWrapper: ["bg-blue-100"],
        icon: "text-black",
        label: "text-white",
      },
      light: {
        base: "text-black",
        iconWrapper: ["bg-blue-400"],
        icon: "text-white",
        label: "text-black",
      },
    },
  },
  defaultVariants: {
    theme: "dark",
  },
});

export type IconItemVariantProps = VariantProps<typeof iconItemVariant>;

export type IconItemReturnVariantProps = ReturnType<typeof iconItemVariant>;
