import { Text } from "../../ui/text";
import {
  useTimelineItem,
  type UseTimelineItemProps,
} from "./use-timeline-item";

export type TimelineItemProps = UseTimelineItemProps;

const TimelineItem = (props: TimelineItemProps) => {
  const { className, styles, theme, data } = useTimelineItem(props);
  const { year, milestones } = data;

  return (
    <li className={styles.base({ className })}>
      <div className={styles.card()}>
        <div className={styles.heading()}>
          <Text tag="time" className={styles.year()} size="h4" weight="medium">
            {year}
          </Text>
        </div>
        <ul className={styles.milestones()}>
          {milestones.map(({ id, title, description }) => (
            <li key={id} className="mb-4">
              <Text
                theme={theme}
                className={styles.title()}
                tag="h3"
                size="h6"
                weight="medium"
              >
                {title}
              </Text>
              {!!description && (
                <Text
                  theme={theme}
                  className={styles.description()}
                  size="description"
                  weight="medium"
                >
                  {description}
                </Text>
              )}
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

export default TimelineItem;
