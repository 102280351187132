import { useState, useEffect, useMemo, useRef, MouseEventHandler } from "react";
import { showcasePlayerVariant } from "./showcase-player.variants";
import { type SectionsHeroShowcase } from "../../types/strapi/sections";

export type UseShowcasePlayerButtonProps = {
  theme: SectionsHeroShowcase["theme"];
  children: React.ReactNode;
  disabled: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const useShowcasePlayerButton = (
  props: UseShowcasePlayerButtonProps
) => {
  const { theme } = props;
  const ref = useRef<HTMLButtonElement>(null);
  const [btnState, setBtnState] = useState<"focus" | "normal">("normal");

  const styles = useMemo(
    () =>
      showcasePlayerVariant({
        theme,
      }),
    [theme]
  );

  useEffect(() => {
    const handleTitleAnimationEnd = () => {
      setBtnState("focus");
    };

    const heroShowcaseAnimationEnd = "hero-showcase-animation::end";

    window?.addEventListener(heroShowcaseAnimationEnd, handleTitleAnimationEnd);

    return () => {
      window?.removeEventListener(
        heroShowcaseAnimationEnd,
        handleTitleAnimationEnd
      );
    };
  }, []);

  const handleAnimationComplete = () => {
    setBtnState("normal");
    ref?.current?.focus();
  };

  const btnVariants = {
    focus: {
      scale: [1, 1.5, 1],
      boxShadow: [
        "0 0 0 transparent",
        "0 0 10px 10px #1040FF",
        "0 0 0 transparent",
      ],
    },
    normal: {
      scale: [1],
    },
  };

  const transition = {
    duration: 0.8,
    times: [0, 0.6, 1],
    ease: "easeOut",
  };

  return {
    ...props,
    ref,
    styles,
    btnVariants,
    transition,
    btnState,
    handleAnimationComplete,
  };
};
