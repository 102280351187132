"use client";

import Script from "next/script";
import { usePathname } from "next-intl/client";

function HubspotContentType() {
  const pathname = usePathname();
  const slug = pathname.replace(/^\/+|\/+$/g, "");

  const blogPattern = /^blog\/[^/]+\/[^/]+$/;

  if (!blogPattern.test(slug)) return <></>;

  return (
    <Script
      id="google-tag-manager-setup"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `var _hsq = (window._hsq = window._hsq || []);
    _hsq.push(["setContentType", "blog-post"]);`,
      }}
    />
  );
}

export default HubspotContentType;
