"use client";

import { Button } from "../ui/button";
import { Vector } from "../ui/vector";
import { Spinner } from "../util/spinner";
import ShowcasePlayerButton from "./showcase-player-button";
import {
  useShowcasePlayer,
  type UseShowcasePlayerProps,
} from "./use-showcase-player";

type ShowcasePlayerProps = UseShowcasePlayerProps;

const ShowcasePlayer = (props: ShowcasePlayerProps) => {
  const { styles, theme, containerRefs, tracks, wavesurfer, cta } =
    useShowcasePlayer(props);

  const { currentTrack, isReady, isPlaying, playPauseAll } = wavesurfer;

  return (
    <div className={styles.base()}>
      <ShowcasePlayerButton
        theme={theme}
        onClick={playPauseAll}
        disabled={!isReady}
        aria-label={isPlaying ? "Pause" : "Play"}
      >
        <Vector
          className={styles.playPauseIcon()}
          name={isPlaying ? "pause" : "play-audio"}
        />
      </ShowcasePlayerButton>
      <div className={styles.waves()}>
        {!isReady && (
          <Spinner
            color={theme === "dark" ? "light" : "dark"}
            className={styles.spinner()}
          />
        )}
        <div className={styles.wavesGroup()}>
          {Object.entries(tracks).map(([id]) => (
            <div
              key={id}
              ref={(el) => {
                if (!el) return;
                containerRefs.current[id] = el;
              }}
              className={styles.wave({
                className: currentTrack === id ? "block" : "hidden",
              })}
            />
          ))}
        </div>
        <div className={styles.cta()}>
          <Button
            color={theme === "dark" ? "white" : "black"}
            size="sm"
            weight="medium"
            appearance="link"
            className={styles.ctaLink()}
            href={cta.url}
            title={cta.text}
          >
            {cta.text}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { ShowcasePlayer };
