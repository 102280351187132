import { useMotionValueEvent, useScroll, useTransform } from "motion/react";
import type { Theme, ModelsTimelineItem } from "../../../types";
import { timelineListVariant } from "./timeline.variants";
import { useRef, useState } from "react";

export type UseTimelineListProps = {
  theme?: Theme;
  timeline: [string, ModelsTimelineItem[]][];
  today: ModelsTimelineItem[];
};

export const useTimelineList = (props: UseTimelineListProps) => {
  const { theme = "dark", timeline, today } = props;
  const [active, setActive] = useState(false);

  const timelineRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: timelineRef,
    offset: ["start center", "end center"],
  });

  const scrollHeight = useTransform(scrollYProgress, [0, 1], ["0%", "150%"]);
  const styles = timelineListVariant({ theme });

  useMotionValueEvent(scrollHeight, "change", (c) => {
    const current = scrollYProgress.get();

    if (current > 0.65) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  return {
    styles,
    theme,
    timelineRef,
    scrollHeight,
    active,
    timeline,
    today,
  };
};
