"use client";

import { Button } from "../../ui/button";
import { Text } from "../../ui/text";
import { useAnnouncement, type UseAnnouncementProps } from "./use-announcement";

export type AnnouncementProps = UseAnnouncementProps;

function Announcement(props: AnnouncementProps) {
  const { className, styles, show, closeAnnouncement, data } =
    useAnnouncement(props);

  if (!show) {
    return null;
  }

  const { title, cta } = data;

  return (
    <div className={styles.base({ className })}>
      <div className={styles.banner()}>
        <Text weight="semibold">
          {title}{" "}
          {!!cta && (
            <Button
              appearance="link"
              color="white"
              weight="medium"
              href={cta.url}
              title={cta.text}
              className={styles.cta()}
              newTab
            >
              {cta.text}
            </Button>
          )}
        </Text>
        <Button startIcon="x-mark" size="lg" onClick={closeAnnouncement} />
      </div>
    </div>
  );
}

export default Announcement;
