import { Text } from "../../ui/text";
import { Vector } from "../../ui/vector";
import { useIconItem, type UseIconItemProps } from "./use-icon-item";

export type IconCardProps = UseIconItemProps;

const IconItem = (props: IconCardProps) => {
  const { className, styles, theme, data } = useIconItem(props);
  const { icon, label } = data;

  return (
    <div className={styles.base({ className })} data-component-name="icon-item">
      <span className={styles.iconWrapper()}>
        <Vector className={styles.icon()} name={icon} />
      </span>
      <Text
        className={styles.label()}
        theme={theme}
        weight="medium"
        size="description"
      >
        {label}
      </Text>
    </div>
  );
};

export default IconItem;
