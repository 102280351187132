import { tv, type VariantProps } from "../../../util/tv";

export const timelineVariant = tv({
  slots: {
    base: ["py-12 md:py-20", "overflow-hidden"],
    wrapper: ["container-fluid"],
    heading: ["flex flex-col", "text-center"],
    title: [],
    description: ["mt-2"],
    content: ["w-full md:max-w-3xl md:mx-auto", "mt-8 lg:mt-12"],
  },
  variants: {
    theme: {
      dark: {
        base: ["bg-black"],
        description: ["text-gray-60"],
      },
      light: {
        base: ["bg-gray-10"],
        description: ["text-gray-60"],
      },
    },
  },
  defaultVariants: {
    theme: "dark",
  },
});

export const timelineListVariant = tv({
  slots: {
    base: ["relative"],
    wrapper: ["relative"],
    list: [
      "relative",
      "overflow-hidden",
      "space-y-8",
      "before:absolute before:inset-0",
      "before:-translate-x-px md:before:translate-x-0",
      "before:h-full before:w-0.5",
      "before:bg-gray-100",
      "before:from-75%",
      "before:ml-5 md:before:mx-auto",
      "after:absolute after:inset-0",
      "after:ml-5 after:-translate-x-px md:after:mx-auto md:after:translate-x-0",
      "after:bg-gradient-to-b after:from-gray-100 after:to-blue-400",
      "after:h-[var(--timeline-progress)] after:w-0.5",
    ],
  },
  variants: {
    theme: {
      dark: {},
      light: {},
    },
  },
  defaultVariants: {
    theme: "dark",
  },
});

export const timelineItemVariant = tv({
  slots: {
    base: [
      "relative",
      "flex items-center justify-end md:justify-between",
      "md:justify-normal md:odd:flex-row-reverse",
      "md:even:text-right",
      "group",
      "last:pb-20",
    ],
    card: ["w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)]", "p-2"],
    heading: ["mb-4"],
    year: [],
    milestones: ["flex flex-col gap-4"],
    title: [],
    description: ["mt-2"],
  },
  variants: {
    theme: {
      dark: {
        year: ["text-blue-400"],
        title: ["text-white"],
        description: ["text-gray-60"],
      },
      light: {
        year: ["text-blue-100"],
        title: ["text-gray-500"],
        description: ["text-gray-60"],
      },
    },
  },
  defaultVariants: {
    theme: "dark",
  },
});

export const timelineTodayVariant = tv({
  slots: {
    base: [
      "group",
      "rounded-2xl",
      "px-8 pt-10 pb-6",
      "animated-border",
      "text-center",
      "bg-showcase-card-gradient from-black from-[10%] to-blue-300 to-[200%]",
      "before:[animation-play-state:running]",
      "transition-all transition-500",
    ],
    title: [],
    label: ["leading-none"],
    description: ["mt-2"],
    list: [
      "flex flex-col",
      "divide-y divide-white/10",
      "border-t border-white/10",
      "mt-8",
    ],
    listItem: ["py-6"],
  },
  variants: {
    theme: {
      dark: {
        title: ["text-blue-400"],
      },
      light: {
        title: ["text-blue-100"],
      },
    },
    active: {
      true: {
        base: ["grayscale-0", "before:animation-play-state:running"],
      },
      false: {
        base: ["grayscale", "before:animation-play-state:paused"],
      },
    },
  },
  defaultVariants: {
    theme: "dark",
    active: false,
  },
});

export type TimelineTodayVariantProps = VariantProps<
  typeof timelineTodayVariant
>;

export type TimelineTodayReturnVariantProps = ReturnType<
  typeof timelineTodayVariant
>;

export type TimelineItemVariantProps = VariantProps<typeof timelineItemVariant>;

export type TimelineItemReturnVariantProps = ReturnType<
  typeof timelineItemVariant
>;

export type TimelineListVariantProps = VariantProps<typeof timelineListVariant>;

export type TimelineListReturnVariantProps = ReturnType<
  typeof timelineListVariant
>;

export type TimelineVariantProps = VariantProps<typeof timelineVariant>;

export type TimelineReturnVariantProps = ReturnType<typeof timelineVariant>;
