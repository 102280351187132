import { tv, type VariantProps } from "../../../util/tv";

export const announcementVariant = tv({
  slots: {
    base: ["relative z-50", "w-full max-w-7xl mx-auto"],
    banner: [
      "flex items-center justify-between gap-2",
      "rounded-xl",
      "px-5 py-4 md:p-6",
      "bg-white/10",
      "backdrop-blur",
    ],
    cta: ["underline"],
  },
  variants: {
    isShow: {
      true: {
        base: ["animate-in fade-in"],
      },
      false: {
        base: ["animate-out fade-out"],
      },
    },
  },
});

export type AnnouncementVariantProps = VariantProps<typeof announcementVariant>;

export type AnnouncementReturnVariantProps = ReturnType<
  typeof announcementVariant
>;
