import { useEffect } from "react";
import type { Theme, ModelsTimelineItem } from "../../../types";
import { timelineTodayVariant } from "./timeline.variants";

export type UseTimelineTodayProps = {
  theme?: Theme;
  active: boolean;
  title: string;
  today: ModelsTimelineItem[];
};

export const useTimelineToday = (props: UseTimelineTodayProps) => {
  const { theme = "dark", active = false, title, today } = props;

  const styles = timelineTodayVariant({ theme, active });

  return {
    styles,
    theme,
    title,
    today,
  };
};
