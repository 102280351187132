import type { ComponentProps, Theme } from "../../../types";
import type { SectionsTimeline } from "../../../types";
import { timelineItemVariant } from "./timeline.variants";

export type UseTimelineItemProps = ComponentProps<
  {
    year: string;
    milestones: SectionsTimeline["timeline"];
  },
  { theme?: Theme }
>;

export const useTimelineItem = (props: UseTimelineItemProps) => {
  const { className = "", theme = "dark", data } = props;

  const styles = timelineItemVariant({ theme });

  return {
    className,
    styles,
    theme,
    data,
  };
};
