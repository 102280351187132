"use client";

import { useCallback, useEffect, useState } from "react";
import { motion, AnimatePresence } from "motion/react";
import { ModelsTag } from "../../../types";

const HeroShowcaseAnimatedTitleRoulette = ({
  words,
}: {
  words: ModelsTag[];
}) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index === words.length - 1) return;

    const timer = setTimeout(
      () => {
        setIndex((prevIndex) => prevIndex + 1);
      },
      index === 0 ? 3000 : 2000
    );

    return () => clearTimeout(timer);
  }, [index, words.length]);

  const handleAnimateComplete = useCallback(
    (currentIndex: number) => {
      if (currentIndex < words.length - 1) return;

      window.dispatchEvent(new CustomEvent("hero-showcase-animation::end"));
    },
    [words.length]
  );

  if (!words.length) return null;

  const currentWord = words[index]!;

  return (
    <>
      {` `}
      <span className="relative">
        <span aria-hidden="true" className="invisible">
          {currentWord.title}
        </span>
        <AnimatePresence>
          <motion.span
            key={currentWord.id}
            className="absolute left-0"
            initial={index > 0 ? { y: 20, opacity: 0 } : undefined}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
            transition={{ ease: "easeInOut" }}
            onAnimationComplete={() => handleAnimateComplete(index)}
          >
            {currentWord.title}
          </motion.span>
        </AnimatePresence>
      </span>
    </>
  );
};

export default HeroShowcaseAnimatedTitleRoulette;
