"use client";

import { motion } from "framer-motion";
import {
  type UseShowcasePlayerButtonProps,
  useShowcasePlayerButton,
} from "./use-showcase-player-button";

type ShowcasePlayerButtonProps = UseShowcasePlayerButtonProps;

function ShowcasePlayerButton(props: ShowcasePlayerButtonProps) {
  const {
    children,
    disabled,
    onClick,
    ref,
    styles,
    btnVariants,
    transition,
    btnState,
    handleAnimationComplete,
  } = useShowcasePlayerButton(props);

  return (
    <div className="relative">
      <motion.button
        ref={ref}
        className={`${styles.playPause()}`}
        onClick={onClick}
        disabled={disabled}
        animate={btnState}
        variants={btnVariants}
        initial="normal"
        transition={transition}
        onAnimationComplete={handleAnimationComplete}
      >
        {children}
      </motion.button>
    </div>
  );
}

export default ShowcasePlayerButton;
