import { tv, type VariantProps } from "../../../../util/tv";

const chordsVariant = tv({
  slots: {
    base: [
      "relative",
      "w-full h-full",
      "pr-2.5",
      "before:absolute before:bottom-0 before:inset-x-0 before:z-10",
      "before:w-full before:h-12",
      "before:bg-gradient-to-t before:from-black before:to-black/0",
      "before:select-none before:pointer-events-none",
    ],
    scrollArea: [
      "w-full h-full",
      "grid grid-cols-1 md:grid-cols-2 gap-2",
      "overflow-y-auto",
      "tiny-scrollbar-showcase tiny-scrollbar-showcase--vertical",
      "p-6 pr-2.5",
    ],
  },
});

export type ChordsVariantProps = VariantProps<typeof chordsVariant>;

export { chordsVariant };
