import { tv, type VariantProps } from "../../util/tv";

export const showcasePlayerVariant = tv({
  slots: {
    base: [
      "w-full",
      "relative",
      "p-4",
      "border",
      "flex items-center gap-5",
      "rounded-2xl",
    ],
    icon: ["w-8 h-8"],
    playPause: [
      "w-13 h-13",
      "shrink-0",
      "flex items-center justify-center",
      "rounded-full",
      "transition-colors duration-500",
      "bg-white not:disabled:hover:bg-gray-100",
      "focus:!outline-blue-400",
      "focus-visible:z-10",
      "focus-visible:outline-2",
      "focus-visible:outline-focus",
      "disabled:opacity-40 disabled:cursor-progress",
      "box-content",
      "shadow-[0_0_0_transparent] shadow-blue-100",
      "z-10",
    ],
    playPauseIcon: ["w-6 h-6", "text-black"],
    animatedBorder: [
      "hidden",
      "absolute",
      "h-full w-full",
      "bg-transparent",
      "border border-8 border-blue-100 rounded-full",
      "z-0",
    ],
    spinner: ["absolute inset-y-0", "m-auto"],
    wavesGroup: ["w-full", "border-r", "pr-4"],
    waves: ["flex items-center w-full gap-4", "relative"],
    wave: ["w-full", "animate-in fade-in duration-1000", "cursor-pointer"],
    cta: ["max-w-24"],
    ctaLink: ["text-center", "leading-snug", "whitespace-normal"],
  },
  variants: {
    theme: {
      dark: {
        base: ["border-gray-500 text-white"],
        wavesGroup: ["border-gray-500"],
      },
      light: {
        base: ["border-gray-500 text-white"],
        wavesGroup: ["border-gray-500"],
      },
    },
    isReady: {
      false: {
        wavesGroup: ["opacity-0"],
      },
      true: {
        wavesGroup: ["animate-in fade-in duration-1000", "opacity-100"],
      },
    },
  },
  defaultVariants: {
    isReady: false,
  },
});

export type ShowcasePlayerVariantProps = VariantProps<
  typeof showcasePlayerVariant
>;

export type showcasePlayerReturnVariantProps = ReturnType<
  typeof showcasePlayerVariant
>;
