"use client";

import { useAppContext } from "../../../providers/app-provider";
import { Announcement } from "../../common/announcement";

export function HeroShowcaseAnnoucement() {
  const { global } = useAppContext();

  return global?.announcement ? (
    <div className="col-span-1 lg:col-span-2">
      <Announcement data={global.announcement} />
    </div>
  ) : null;
}
